.processor {
    background: #61dafb;
    margin: 5px;
    padding: 10px;

    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;

}